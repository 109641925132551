<template>
    <Section
        width="md"
        class="index"
    >
        <h1>Welcome to <span>Compinero</span></h1>
        <h3>Choose your market below.</h3>

        <ul>
            <li
                v-for="(locale, index) in locales"
                :key="index"
            >
                <a :href="'/' + locale.site + '/'">
                    <img :src="'/icons/' + locale.icon " />
                    <span>{{ locale.title }}</span>
                </a>
            </li>
        </ul>
    </Section>
</template>

<script>
    let siteConfig = require('@/site.config.js').default;

    // TODO: add meta descriptions
    export default {
        head() {
            return {
                title: 'Compinero.com - Select market',
                link: [
                    {
                        rel: 'canonical',
                        href: process.env.APP_URL
                    }
                ]
            }
        },
        data() {
            return {
                locales: siteConfig.locales
            }
        }
    }

</script>

<style lang="scss" scoped>
.index::v-deep {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0;
    background: $blue;

    .section-inner {
        @include flex;
    }

    h1,
    h3,
    p {
        color: $white;
        text-align: center;
        width: 100%;
    }

    h3,
    p { @include spacing(margin, 8, (top, bottom)); }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            a {
                @include flex(start);
                @include spacing(padding, 2, (top, bottom));
                color: $white;
                font-weight: bold;

                &:hover { color: $orange; }

                img {
                    @include size(40px);
                    @include spacing(margin, 4, right);
                }
            }
        }
    }
}

</style>
